import axios from 'axios';

import { JOBS_API_URL, CMS_API_URL } from '../constants/main';

const PublicService = {
  async uploadAvatar(image) {
    const form = new FormData();
    form.append('Image', image);
    form.append('Type', 'avatar');

    const response = await axios.post(`${CMS_API_URL}/uploads/images`, form);
    return response.data;
  },

  async getAssessment({ assessmentId }) {
    const response = await axios.get(
      `${JOBS_API_URL}/assessments/${assessmentId}`
    );
    return response.data;
  },

  async answerAssessmentQuestion({ assessmentId, answerId }) {
    const response = await axios.post(
      `${JOBS_API_URL}/jobapplicationassessments/${assessmentId}/input`,
      {
        assessmentQuestionAnswerId: answerId,
      }
    );
    return response.data;
  },

  async submitAssessment({ jobApplicationId, jobApplicationAssessmentId }) {
    const response = await axios.post(
      `${JOBS_API_URL}/jobapplications/${jobApplicationId}/assessment/submit`,
      { jobApplicationAssessmentId }
    );
    return response.data;
  },

  async addJobToFavorites({ jobId }) {
    const response = await axios.post(
      `${JOBS_API_URL}/jobposts/${jobId}/favorites`
    );
    return response.data;
  },

  async removeJobFromFavorites({ jobId }) {
    const response = await axios.delete(
      `${JOBS_API_URL}/jobposts/${jobId}/favorites`
    );
    return response.data;
  },

  async startJobApplication({ jobId }) {
    const response = await axios.post(
      `${JOBS_API_URL}/jobposts/${jobId}/actions`,
      {
        type: 'StartApplication',
      }
    );
    return response.data;
  },

  async finishedJobApplication({ jobId }) {
    const response = await axios.post(
      `${JOBS_API_URL}/jobposts/${jobId}/actions`,
      {
        type: 'FinishedApplication',
      }
    );
    return response.data;
  },

  async leftJobApplication({ jobId }) {
    const response = await axios.post(
      `${JOBS_API_URL}/jobposts/${jobId}/actions`,
      {
        type: 'LeftApplication',
      }
    );
    return response.data;
  },

  async addViewToJobPost({ jobId, ZapSessionId }) {
    const response = await axios.post(
      `${JOBS_API_URL}/jobposts/${jobId}/views`,
      null,
      {
        headers: {
          'Zap-Session-Id': ZapSessionId,
        },
      }
    );
    return response.data;
  },

  async createJobApplication({ jobPostId, coverFileName }) {
    const response = await axios.post(`${JOBS_API_URL}/jobapplications`, {
      jobPostId,
      // birthDate: new Date(),
      //   coverFileName: 'string',
      // locationId: 1,
      status: 'Draft',
      // graduationId: 1,
      coverFileName,
    });
    return response.data;
  },

  async deleteJobApplication({ jobApplicationId }) {
    const response = await axios.delete(
      `${JOBS_API_URL}/jobapplications/${jobApplicationId}`
    );
    return response.data;
  },

  async updateJobApplication({
    jobApplicationId,
    graduationId,
    applicantLocation,
    birthDate,
    jobPostId,
    coverFileName,
    status,
  }) {
    const response = await axios.put(
      `${JOBS_API_URL}/jobapplications/${jobApplicationId}`,
      {
        jobPostId,
        status,
        graduationId,
        applicantLocation,
        birthDate,
        coverFileName,
      }
    );
    return response.data;
  },

  async uploadVideo(file, onUploadProgress, isSafari) {
    const form = new FormData();

    const filename = isSafari
      ? `${Math.random()}.mp4`
      : `${Math.random()}.webm`;

    form.append('File', file, filename);

    const response = await axios.post(`${CMS_API_URL}/uploads/videos`, form, {
      onUploadProgress,
    });
    return response.data;
  },

  async uploadImage(image, onUploadProgress) {
    const form = new FormData();
    form.append('Image', image, 'image.jpg');
    form.append('Type', 'jobapplicationcover');
    const response = await axios.post(`${CMS_API_URL}/uploads/images`, form, {
      onUploadProgress,
    });
    return response.data;
  },

  async createJobApplicationVideo({
    jobApplicationId,
    url,
    fileName,
    mimeType,
    videoResumeQuestionId,
  }) {
    const response = await axios.post(
      `${JOBS_API_URL}/jobapplications/${jobApplicationId}/videos`,
      {
        fileName,
        url,
        mimeType,
        videoResumeQuestionId,
      }
    );
    return response.data;
  },

  async createJobApplicationExp({
    jobApplicationId,
    jobTitle,
    company,
    startDate,
    endDate,
  }) {
    const response = await axios.post(
      `${JOBS_API_URL}/jobapplications/${jobApplicationId}/experiences`,
      {
        jobTitle,
        company,
        startDate,
        endDate,
      }
    );
    return response.data;
  },

  async uploadFile({ file }) {
    const form = new FormData();
    form.append('File', file);
    const response = await axios.post(`${CMS_API_URL}/uploads/files`, form);

    return response.data;
  },

  async uploadVideoFile({ file }) {
    const form = new FormData();
    form.append('File', file);
    const response = await axios.post(`${CMS_API_URL}/uploads/videos`, form);

    return response.data;
  },

  async uploadJobApplicationAttach({ file, jobApplicationId }) {
    const uploadedFileData = file.url ? file : await this.uploadFile({ file });

    const response = await axios.post(
      `${JOBS_API_URL}/jobapplications/${jobApplicationId}/attachments`,
      {
        fileName: uploadedFileData.fileName,
        url: uploadedFileData.uri || uploadedFileData.url,
        mimeType: uploadedFileData.mimeType,
      }
    );

    return response.data;
  },

  async getJobApplications() {
    const response = await axios.get(`${JOBS_API_URL}/myjobapplications`);
    return response.data;
  },

  async createJobApplicationFeedback({
    feedbackNote,
    feedbackRating,
    isRecommended,
    jobApplicationId,
  }) {
    const response = await axios.post(
      `${JOBS_API_URL}/jobapplications/${jobApplicationId}/feedbacks`,
      {
        feedbackNote,
        feedbackRating,
        isRecommended,
      }
    );
    return response.data;
  },

  async sendJobApplicationMessage({ message, jobApplicationId }) {
    const response = await axios.post(
      `${JOBS_API_URL}/jobapplications/${jobApplicationId}/messages`,
      {
        message,
      }
    );
    return response.data;
  },

  async passJobAssessment({ jobApplicationId }) {
    const response = await axios.put(
      `${JOBS_API_URL}/jobapplications/${jobApplicationId}/status`,
      {
        status: 'Screen',
        subStatus: 'Passed',
      }
    );
    return response.data;
  },

  async acceptJobInvite({ jobApplicationId }) {
    const response = await axios.put(
      `${JOBS_API_URL}/jobapplications/${jobApplicationId}/status`,
      {
        status: 'New',
        subStatus: 'Accepted',
      }
    );
    return response.data;
  },

  async acceptJobWithAssessmentInvite({ jobApplicationId }) {
    const response = await axios.put(
      `${JOBS_API_URL}/jobapplications/${jobApplicationId}/status`,
      {
        status: 'Screen',
        subStatus: 'Requested',
      }
    );
    return response.data;
  },

  async declineJobInvite({ jobApplicationId }) {
    const response = await axios.put(
      `${JOBS_API_URL}/jobapplications/${jobApplicationId}/status`,
      {
        status: 'New',
        subStatus: 'Declined',
      }
    );
    return response.data;
  },

  async acceptInterviewSchedule({ jobApplicationId, interviewId }) {
    const response = await axios.put(
      `${JOBS_API_URL}/jobapplications/${jobApplicationId}/interviews/${interviewId}`,
      {
        status: 'Accepted',
      }
    );
    return response.data;
  },

  async declineInterviewSchedule({
    jobApplicationId,
    interviewId,
    declineReason,
    suggestedInterviewDate,
    suggestedStartTime,
    status = 'Declined',
  }) {
    const response = await axios.put(
      `${JOBS_API_URL}/jobapplications/${jobApplicationId}/interviews/${interviewId}`,
      {
        status,
        declineReason,
        suggestedInterviewDate,
        suggestedStartTime,
      }
    );
    return response.data;
  },

  async acceptOffer({ jobApplicationId, offerId }) {
    const response = await axios.put(
      `${JOBS_API_URL}/jobapplications/${jobApplicationId}/offers/${offerId}`,
      {
        status: 'Accepted',
      }
    );
    return response.data;
  },

  async declineOffer({
    jobApplicationId,
    offerId,
    declineReason,
    amount,
    declineMessage,
    status = 'Declined',
  }) {
    const response = await axios.put(
      `${JOBS_API_URL}/jobapplications/${jobApplicationId}/offers/${offerId}`,
      {
        status,
        declineReson: declineReason,
        amount,
        declineMessage,
      }
    );
    return response.data;
  },

  async acceptOnboarding({ jobApplicationId, onboardingId }) {
    const response = await axios.put(
      `${JOBS_API_URL}/jobapplications/${jobApplicationId}/onboardings/${onboardingId}`,
      {
        status: 'Accepted',
      }
    );
    return response.data;
  },

  async acceptJoining({ jobApplicationId, joiningId }) {
    const response = await axios.put(
      `${JOBS_API_URL}/jobapplications/${jobApplicationId}/joinings/${joiningId}`,
      {
        status: 'Accepted',
      }
    );
    return response.data;
  },

  async declineJoining({
    jobApplicationId,
    joiningId,
    declineReason,
    suggestedJoiningDate,
    suggestedStartTime,
    status = 'Declined',
  }) {
    const response = await axios.put(
      `${JOBS_API_URL}/jobapplications/${jobApplicationId}/joinings/${joiningId}`,
      {
        status,
        declineReason,
        suggestedJoiningDate,
        suggestedStartTime,
      }
    );
    return response.data;
  },

  async declineOnboarding({
    status = 'Declined',
    jobApplicationId,
    onboardingId,
    declineReason,
    suggestedOnboardingDate,
    suggestedStartTime,
  }) {
    const response = await axios.put(
      `${JOBS_API_URL}/jobapplications/${jobApplicationId}/onboardings/${onboardingId}`,
      {
        status,
        declineReason,
        suggestedOnboardingDate,
        suggestedStartTime,
      }
    );
    return response.data;
  },

  async getNotifications() {
    const response = await axios.get(`${JOBS_API_URL}/notifications`);
    return response.data;
  },

  async readNotification(notificationId) {
    const response = await axios.post(
      `${JOBS_API_URL}/notifications/${notificationId}/read`
    );
    return response.data;
  },

  async readAllNotifications() {
    const response = await axios.post(`${JOBS_API_URL}/notifications/read`);
    return response.data;
  },

  async deleteNotification(notificationId) {
    const response = await axios.delete(
      `${JOBS_API_URL}/notifications/${notificationId}`
    );
    return response.data;
  },

  async createMessage({ jobApplicationId, message }) {
    const response = await axios.post(
      `${JOBS_API_URL}/jobapplications/${jobApplicationId}/messages`,
      {
        message,
      }
    );
    return response.data;
  },

  async readMessages({ jobApplicationId }) {
    const response = await axios.post(
      `${JOBS_API_URL}/jobapplications/${jobApplicationId}/messages/read`
    );
    return response.data;
  },

  async getNewJobPostsCount() {
    const response = await axios.get(`${JOBS_API_URL}/jobposts/newcount`);
    return response.data;
  },

  async withdrawJobApplication({ jobApplicationId }) {
    const response = await axios.post(
      `${JOBS_API_URL}/jobapplications/${jobApplicationId}/withdraw`
    );
    return response.data;
  },

  async getTenantSettings() {
    const response = await axios.get(`${CMS_API_URL}/tenantsettings`);
    return response.data;
  },

  async getFlowQFormsDetails(jobApplicationId) {
    const response = await axios.get(
      `${JOBS_API_URL}/jobapplications/${jobApplicationId}/forms`
    );
    return response.data;
  },
};

export default PublicService;
