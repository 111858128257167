import React, { useState, useEffect } from 'react';

import { useQuery } from '@tanstack/react-query';
import Inputmask from 'inputmask';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import MapModal from '../../../../../../../modals/MapModal';
import DocModal from '../../../../../../../modals/DocModal';
import Textarea from '../../../../../../Textarea';

import classes from './styles.module.scss';
import UserService from '../../../../../../../services/UserService';

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export default function OfferForm({
  offer,
  jobId,
  counterOffer,
  counterOfferAmount,
  setCounterOfferAmount,
  message,
  setMessage,
}) {
  const [isDocModalVisible, setIsDocModalVisible] = useState(false);
  const [isMapModalVisible, setIsMapModalVisible] = useState(false);

  const { t } = useTranslation();

  const { data: user } = useQuery({
    queryKey: ['me'],
    queryFn: UserService.getMe,
  });

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  const lastOffer = offer?.statusHistory?.at(-1);
  const hasNewOffer =
    (offer?.status === 'Countered' || offer?.status === 'CounterAccepted') &&
    lastOffer?.performedBy !== user?.id;
  const reversedStatusHistory = [...offer?.statusHistory]?.reverse?.();
  const myLastOffer = reversedStatusHistory?.find(
    (history) => history.performedBy === user?.id
  );

  const lastOfferAmount = myLastOffer?.amount;

  const isCounterOfferDeclined = offer?.status === 'Declined';

  let currencySymbol = '$';

  if (offer?.currency === 'TRY') {
    currencySymbol = '₺';
  }

  if (offer?.currency === 'EUR') {
    currencySymbol = '€';
  }

  useEffect(() => {
    Inputmask.extendAliases({
      currencyWithSybmol: {
        prefix: currencySymbol,
        groupSeparator: '.',
        alias: 'numeric',
        placeholder: '0',
        autoGroup: true,
        digits: 2,
        digitsOptional: false,
        clearMaskOnLostFocus: false,
      },
    });
    Inputmask({ alias: 'currencyWithSybmol' }).mask(`.CurrencyInput`);
  }, [currencySymbol]);

  const hasAttachment = !!offer?.jobApplicationOfferAttachments.length;

  const counterOfferForm = (
    <div className={classNames(classes.counterOfferContainer, classes.row)}>
      <h5 className={classes.heading}>
        {t(
          'components.MyDashboard.JobApplicationInfo.JobApplicationStatuses.Offer.Offered.OfferForm.headingMyOffer'
        )}
      </h5>
      <input
        readOnly={!counterOffer}
        type="text"
        className="CurrencyInput"
        value={counterOfferAmount || offer?.counterAmount}
        onChange={(event) => setCounterOfferAmount(event.target.value)}
      />
    </div>
  );

  return (
    <div className={classes.OfferForm}>
      <div className={classes.row}>
        <div className={classes.col}>
          <h4 className={classes.offerTitle}>
            {t(
              'components.MyDashboard.JobApplicationInfo.JobApplicationStatuses.Offer.Offered.OfferForm.offerTitle'
            )}{' '}
            {hasNewOffer && (
              <span className={classes.new}>({t('common.new')})</span>
            )}
          </h4>
        </div>
        <div className={classes.col}>
          <span
            className={classNames(classes.offerAmount, {
              [classes.counterOffer]: counterOffer,
              [classes.newOffer]: hasNewOffer,
            })}
          >
            {currencySymbol}
            {formatter.format(offer?.amount)?.substring(1)}
          </span>
        </div>
      </div>
      <div
        className={classNames(classes.row, classes.myCounterOffer, {
          [classes.declined]: isCounterOfferDeclined,
        })}
        style={{
          visibility:
            hasNewOffer || isCounterOfferDeclined ? 'visible' : 'hidden',
        }}
      >
        <div className={classes.col}>
          <h6 className={classes.counterTitle}>
            {isCounterOfferDeclined
              ? t(
                  'components.MyDashboard.JobApplicationInfo.JobApplicationStatuses.Offer.Offered.OfferForm.headingCounterDeclined'
                )
              : t(
                  'components.MyDashboard.JobApplicationInfo.JobApplicationStatuses.Offer.Offered.OfferForm.headingMyCounterOffer'
                )}
          </h6>
        </div>
        <div className={classes.col}>
          {currencySymbol}
          {formatter.format?.(lastOfferAmount)?.substring?.(1)}
        </div>
      </div>
      <div className={classes.row}>
        <div className={classes.col}>
          <h5 className={classes.heading}>
            {t(
              'components.MyDashboard.JobApplicationInfo.JobApplicationStatuses.Offer.Offered.OfferForm.headingPosition'
            )}
          </h5>
        </div>
        <div className={classes.col}>
          {hasAttachment && (
            <div
              className={classes.additionalInfo}
              onClick={() => setIsDocModalVisible(true)}
            >
              {t(
                'components.MyDashboard.JobApplicationInfo.JobApplicationStatuses.Offer.Offered.OfferForm.additionalInfo'
              )}
            </div>
          )}
          {(counterOffer || offer?.counterAmount) &&
            !hasAttachment &&
            !isTabletOrMobile &&
            counterOfferForm}
        </div>
      </div>
      <div className={classNames(classes.row, classes.jobTitle)}>
        <span className={classes.heading}>{offer?.customJobTitle}</span>
        {(counterOffer || offer?.counterAmount) &&
          hasAttachment &&
          !isTabletOrMobile &&
          counterOfferForm}
      </div>
      <div className={classes.row}>
        <span className={classes.jobId}>
          {t(
            'components.MyDashboard.JobApplicationInfo.JobApplicationStatuses.Offer.Offered.OfferForm.jobId'
          )}
          : {jobId}
        </span>
      </div>
      {(counterOffer || offer?.counterAmount) && isTabletOrMobile && (
        <div className={classNames(classes.row, classes.mobileMyOffer)}>
          {counterOfferForm}
        </div>
      )}
      <div className={classes.row}>
        <h5 className={classes.heading}>
          {t(
            'components.MyDashboard.JobApplicationInfo.JobApplicationStatuses.Offer.Offered.OfferForm.headingWorkLocation'
          )}
        </h5>
      </div>
      <div className={classes.row}>
        <div className={classes.workLocation}>
          <span className={classes.location}>{offer?.locationName}</span>
          {offer?.location?.latitude && (
            <button type="button" onClick={() => setIsMapModalVisible(true)}>
              {t(
                'components.MyDashboard.JobApplicationInfo.JobApplicationStatuses.Offer.Offered.OfferForm.clickToSeeInMap'
              )}
            </button>
          )}
        </div>
      </div>
      {(counterOffer || offer?.declineMessage) && (
        <>
          {!counterOffer && (
            <div className={classes.row}>
              <h5 className={classes.heading}>
                {t(
                  'components.MyDashboard.JobApplicationInfo.JobApplicationStatuses.Offer.Offered.OfferForm.headingMessage'
                )}
              </h5>
            </div>
          )}
          <div className={classes.row}>
            {counterOffer ? (
              <Textarea
                label={t(
                  'components.MyDashboard.JobApplicationInfo.JobApplicationStatuses.Offer.Offered.OfferForm.headingMessage'
                )}
                height={64}
                placeholder={t(
                  'components.MyDashboard.JobApplicationInfo.JobApplicationStatuses.Offer.Offered.OfferForm.typeYourMessage'
                )}
                name="message"
                value={message}
                setValue={setMessage}
              />
            ) : (
              <p className={classes.message}>{offer?.declineMessage}</p>
            )}
          </div>
        </>
      )}

      <div className={`${classes.row} ${classes.recruiterMessage}`}>
        <h5 className={classes.heading}>
          {t(
            'components.MyDashboard.JobApplicationInfo.JobApplicationStatuses.Offer.Offered.OfferForm.headingRecruiterMessage'
          )}
        </h5>
      </div>
      <div className={classes.row}>
        <p className={classes.message}>{offer?.note}</p>
      </div>

      <DocModal
        show={isDocModalVisible}
        handleClose={() => setIsDocModalVisible(false)}
        jobTitle={offer?.jobApplicationOfferAttachments[0]?.displayName}
        doc={offer?.jobApplicationOfferAttachments[0]?.url}
      />
      <MapModal
        show={isMapModalVisible}
        handleClose={() => setIsMapModalVisible(false)}
        locations={[offer?.location]}
        offer
      />
    </div>
  );
}
