import React, { useState, useEffect, useContext, useRef } from 'react';

import { useMediaQuery } from 'react-responsive';
import { useQueryClient } from '@tanstack/react-query';

import Message from './Message';
import BlackButton from '../BlackButton';
import Button from '../../../Button';
import ReplyBox from './ReplyBox';

import { UiContext } from '../../../../context/UiContext';
import PublicService from '../../../../services/PublicService';
import backArrowIcon from '../../../../assets/images/dashboard/back-arrow.svg';
import classes from './styles.module.scss';

export default function Messages({
  goBack,
  messages,
  refetch,
  jobApplicationId,
  fromNotification,
}) {
  const [newMessage, setNewMessage] = useState('');

  const messagesContainerRef = useRef();

  const { setIsFetching } = useContext(UiContext);

  const queryClient = useQueryClient();

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  const sendMessage = async () => {
    try {
      if (newMessage.trim()) {
        setIsFetching(true);
        await PublicService.createMessage({
          message: newMessage,
          jobApplicationId,
        });
        setNewMessage('');
        await refetch();

        // If the user is coming from a notification, we need to refetch notifications to display the sent message
        if (fromNotification) {
          await queryClient.invalidateQueries({ queryKey: ['notifications'] });
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    const readMessages = async () => {
      try {
        await PublicService.readMessages({ jobApplicationId });
        refetch();
      } catch (error) {
        console.log(error);
      }
    };

    readMessages();
  }, [jobApplicationId, refetch, messages?.length]);

  useEffect(() => {
    const messagesContainer = messagesContainerRef.current;

    if (messagesContainer) {
      messagesContainer.scrollTop = messagesContainer.scrollHeight;
    }
  }, [messages]);

  return (
    <div className={classes.Messages}>
      <div className={classes.header}>
        <button type="button" className={classes.backButton} onClick={goBack}>
          <img src={backArrowIcon} alt="Back" />
        </button>
      </div>
      <div className={classes.messagesContainer} ref={messagesContainerRef}>
        <ul className={classes.messagesList}>
          {messages
            ?.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
            .map((message) => (
              <Message key={message.id} message={message} />
            ))}
        </ul>
        <ReplyBox
          newMessage={newMessage}
          setNewMessage={setNewMessage}
          refetch={refetch}
        />
      </div>
      <div className={classes.buttons}>
        <BlackButton
          onClick={() => setNewMessage('')}
          width={isTabletOrMobile ? '' : 300}
          height={isTabletOrMobile ? 54 : 64}
        >
          Clear
        </BlackButton>
        <Button
          width={isTabletOrMobile ? '' : 300}
          height={isTabletOrMobile ? 54 : 64}
          style={{ fontSize: 24, fontWeight: 500 }}
          onClick={sendMessage}
        >
          Submit
        </Button>
      </div>
    </div>
  );
}
