import React from 'react';

import Modal from 'react-bootstrap/Modal';
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';

import classes from './styles.module.scss';

export default function DocModal({ show, handleClose, doc, jobTitle }) {
  const fileType = doc?.split('.')?.pop();

  return (
    <Modal
      show={show}
      centered
      className={classes.DocModal}
      backdropClassName={classes.backdrop}
      contentClassName={classes.modalContent}
      dialogClassName={classes.dialog}
    >
      <div className={classes.content}>
        <h1>{jobTitle}</h1>
        <i className={classes.closeIcon} onClick={handleClose}>
          Close
        </i>
        <div className={classes.doc}>
          <DocViewer
            documents={[
              {
                uri: doc,
                fileType,
              },
            ]}
            pluginRenderers={DocViewerRenderers}
            className={classes.docViewer}
            prefetchMethod="GET"
          />
        </div>
      </div>
    </Modal>
  );
}
