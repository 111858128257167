import React from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';
import 'bootstrap/dist/css/bootstrap.min.css';

import Button from '../../components/Button';
import BlackButton from '../../components/MyDashboard/JobApplicationInfo/BlackButton';

import warningIcon from '../../assets/images/warning.svg';
import classes from './styles.module.scss';

export default function AlertModal({
  show,
  handleClose,
  title,
  text,
  onConfirm,
  onCancel,
  noCancel,
}) {
  const { t } = useTranslation();

  return (
    <Modal
      show={show}
      centered
      className={classNames(classes.AlertModal, 'alert-modal')}
      backdropClassName={classes.backdrop}
      contentClassName={classes.modalContent}
      dialogClassName={classes.dialog}
    >
      <div className={classes.content}>
        <header>
          <i className={classes.closeIcon} onClick={handleClose}>
            Close
          </i>
        </header>
        <img
          width={40}
          height={40}
          className={classes.icon}
          src={warningIcon}
          alt="Icon"
        />
        <h1>{title}</h1>
        <p>{text}</p>
        {onConfirm && !noCancel ? (
          <div className={classes.buttons}>
            <BlackButton
              onClick={() => {
                onConfirm?.();
                handleClose();
              }}
              width={127}
              height={60}
              style={{ fontSize: 24 }}
            >
              {t('common.yes')}
            </BlackButton>
            <Button
              onClick={() => {
                onCancel?.();
                handleClose();
              }}
              width={127}
              height={60}
              style={{ fontSize: 24 }}
            >
              {t('common.no')}
            </Button>
          </div>
        ) : (
          <Button
            onClick={() => {
              onConfirm?.();
              onCancel?.();
              handleClose();
            }}
            width={143}
            height={60}
            style={{ fontSize: 24 }}
          >
            {t('common.gotIt')}
          </Button>
        )}
      </div>
    </Modal>
  );
}
